
import React from 'react'
import Head from 'next/head'
import { Query } from '@apollo/client/react/components'
import get from 'lodash/get'
import dynamic from 'next/dynamic'
import truncate from 'lodash/truncate'
import stripHtml from 'string-strip-html'
import { withNamespaces } from '>/i18n'

import withLayout from '~/hoc/withLayout'
import withFestivalContext from '~/hoc/withFestivalContext'
import { getFestivalRangeShort } from '~/utils/helpers'

import { FESTIVAL_EDITION_STATES, DESCRIPTION_LIMIT } from '~/utils/consts'
import { GET_CURRENT_FESTIVAL_EDITION } from '~/utils/queries.gql'

import Modal from '~/components/Modal'
import { commonModals } from '~/components/Modal/modalsList'
import config from '~/config'

const Festival = dynamic(() => import('~/components/Festival/gw/index'))

const HeadTags = ({ festival }) => {
  const { festivalName, t, state } = festival

  const description = state === FESTIVAL_EDITION_STATES.VOUCHER ?
    t(`app.${festivalName}.description_inactive`) :
    t(`app.${festivalName}.description`, { range: getFestivalRangeShort(festival) })

  const formattedName = `${t(`app.${festivalName}.title`)}`
  const formattedDescription = truncate(stripHtml(description).result, {
    'length': DESCRIPTION_LIMIT,
    'separator': ' '
  })
  const image = `${config().clubUrl}/static/assets/festival/${festivalName}/share.png` || undefined

  return (
    <Head>
      <title>{formattedName}</title>
      <meta name='description' content={formattedDescription} />
      <meta property='og:title' content={formattedName} key='title' />
      <meta property='og:description' content={formattedDescription} key='description' />
      {image && <meta property='og:image' content={image} key='image' />}
      { /* Meta Pixel Code */ }
      {config().metaPixelId && (
        <>
          <script
            async
            dangerouslySetInnerHTML={{
              __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '${config().metaPixelId}');
              fbq('track', 'PageView');`
            }}
          />
          <noscript><img height='1' width='1' style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${config().metaPixelId}&ev=PageView&noscript=1`}
          /></noscript>
        </>
      )}
    </Head>
  )
}

export default
@withLayout({
  namespaces: ['tooltips', 'modal'],
  contentClassName: 'festival'
})
@withFestivalContext
@withNamespaces(['meta', 'homepage-gw'])
class Index extends React.Component {
  render () {
    const { festivalName, t } = this.props
    const festival = config(festivalName).festival

    return (
      <>
        <Query
          query={GET_CURRENT_FESTIVAL_EDITION}
          variables={{ code: festivalName }}
        >
          {({ data }) => {
            const isVoucher = get(data, 'currentFestivalEdition.state') === FESTIVAL_EDITION_STATES.VOUCHER
            const isSale = get(data, 'currentFestivalEdition.state') === FESTIVAL_EDITION_STATES.SALE

            return (
              <>
                <HeadTags festival={{ festivalName, t, ...get(data, 'currentFestivalEdition') }} />
                <Festival isVoucher={isVoucher} isSale={isSale} />
                {config().chatbotHash && festival.chatbotEnabled && <script async type='text/javascript' src={`https://cdn.thulium.com/apps/chat-widget/chat-loader.js?hash=${config().chatbotHash}`} />}
              </>
            )
          }}
        </Query>
        <Modal components={commonModals} />
      </>
    )
  }
}
